export const ptBR: I18NProps = {
  name: 'Português (Brasil)',
  code: 'pt-BR',
  values: {
    Active: 'Ativo',
    Inactive: 'Inativo',
    Premium: 'Prêmio',
    Deductible: 'Franquia',

    'Insurance broker': 'Corretora de seguros',
    'Insurance company': 'Seguradora',
    'Insurance types': 'Tipos de seguro',
    'Insurance type': 'Tipo de seguro',
    'Insurance companies': 'Seguradoras',
    'Delete insurance': 'Excluir seguro',
    'Disable insurance': 'Desativar seguro',
    'An error occurred while opening the file window.': 'Ocorreu um erro ao abrir a janela de arquivos.',
    'Are you sure you want to delete the insurance "{key}"?': 'Tem certeza que deseja excluir o seguro "{key}"?',
    'Are you sure you want to disable the insurance "{key}"?': 'Tem certeza que deseja desativar o seguro "{key}"?',
    'Are you sure you want to delete the insurance type "{type}"?': 'Tem certeza que deseja excluir o tipo de seguro "{type}"?', // eslint-disable-line prettier/prettier
    'Are you sure you want to delete the insurance company "{key}"?': 'Tem certeza que deseja excluir a seguradora "{key}"?', // eslint-disable-line prettier/prettier
    'New insurance company': 'Nova seguradora',
    'New type': 'Novo tipo',
    'New insurance': 'Novo seguro',
    'Policy number': 'Número da apólice',
    'Expires in next': 'Expira nos próximos',
    '7 days': '7 dias',
    '15 days': '15 dias',
    '30 days': '30 dias',
    'Expires at': 'Expira em'
  }
}
