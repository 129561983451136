import { i18n } from 'methone/services/i18n'

export const MENU_AREA = 'insurance-broker'

export const MENU_PATHS = {
  INSURANCES: `insurances`,
  TYPES: `types`,
  COMPANIES: `companies`
}

export const expiresInNext: Options = [
  {
    value: '7 days',
    get label() {
      return i18n('7 days')
    }
  },
  {
    value: '15 days',
    get label() {
      return i18n('15 days')
    }
  },
  {
    value: '30 days',
    get label() {
      return i18n('30 days')
    }
  }
]

export const status: Options = [
  {
    value: 'false',
    get label() {
      return i18n('Inactive')
    }
  },
  {
    value: 'true',
    get label() {
      return i18n('Active')
    }
  }
]
