import { Container } from 'react-bootstrap'

import styled from 'styled-components'

export const EditorFieldset = styled.fieldset`
  > .card {
    > .card-body {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-2);

      > .form-row {
        &:nth-child(2) {
          display: grid;
          grid-template-areas: 'PLN BNC ICN ITP';
          grid-template-columns: 200px 200px 1fr 1fr;
          gap: var(--spacing-2);
        }

        &:nth-child(3) {
          display: grid;
          grid-template-areas: 'VLR DDC EAT STS';
          grid-template-columns: 1fr 1fr 1fr 1fr;
          gap: var(--spacing-2);
        }

        &:nth-child(4) {
          flex: 1;

          > .form-textarea-container--details {
            height: 100%;

            > textarea {
              resize: none;
              flex: 1;
            }
          }
        }
      }
    }
  }
`

export const EditorContainer = styled(Container)`
  padding: var(--spacing-4);

  > form {
    > .card.actions-footer {
      margin-top: var(--spacing-2);

      > .card-body {
        display: flex;
        justify-content: space-between;

        > .actions-area {
          display: flex;
          justify-content: center;
          align-items: center;

          > button {
            padding-top: 5px;
            padding-bottom: 5px;

            &.btn.btn-link {
              color: var(--gray-0);
              text-decoration: none;
            }
          }

          > span {
            margin-left: 10px;
          }
        }

        > .required-warn {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: var(--font-semibold);
          color: var(--red-500);
        }
      }
    }
  }
`
