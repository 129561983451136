import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'
import { api } from 'methone/utils/api'

import { REST_ENDPOINTS, PLUGIN_NAME } from 'plugin-insurances/shared/constants'
import { InsuranceCreateDTO } from 'plugin-insurances/shared/dto/InsuranceCreateDTO'
import { InsuranceResponseDTO } from 'plugin-insurances/shared/dto/InsuranceResponseDTO'
import { InsuranceResumeResponseDTO } from 'plugin-insurances/shared/dto/InsuranceResumeResponseDTO'
import { InsuranceUpdateDTO } from 'plugin-insurances/shared/dto/InsuranceUpdateDTO'

const ENDPOINT = `${PLUGIN_NAME}/${REST_ENDPOINTS.INSURANCES}`

// eslint-disable-next-line prettier/prettier
export async function listInsurances(params?: ListInsurancesFilters): Promise<PaginationDTO<InsuranceResumeResponseDTO>> {
  const { data: response } = await api.get(`/${ENDPOINT}`, { params })

  return response
}

export async function findInsurance(id: string): Promise<InsuranceResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}`)

  return response
}

export async function createInsurance(data: InsuranceCreateDTO): Promise<InsuranceResumeResponseDTO> {
  const { data: response } = await api.post(`/${ENDPOINT}`, data)

  return response
}

export async function updateInsurance(id: string, data: InsuranceUpdateDTO): Promise<InsuranceResumeResponseDTO> {
  const { data: response } = await api.put(`/${ENDPOINT}/${id}`, data)

  return response
}

export async function deleteInsurance(id: string): Promise<void> {
  await api.delete(`/${ENDPOINT}/${id}`)
}
