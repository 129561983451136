import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { AutoCompleteHookForm } from 'methone/components/AutoComplete'
import { ClientSelectorHookForm } from 'methone/components/ClientSelector'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormDatePicker, HookFormInput, HookFormSelect, HookFormTextarea } from 'methone/components/ui/Forms'
import { i18n } from 'methone/services/i18n'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { createInsuranceCompany, listInsuranceCompanies } from 'plugin-insurances/services/insuranceCompanies'
import { createInsurance, findInsurance, updateInsurance } from 'plugin-insurances/services/insurances'
import { createInsuranceType, listInsuranceTypes } from 'plugin-insurances/services/insuranceTypes'
import { InsuranceResponseDTO } from 'plugin-insurances/shared/dto/InsuranceResponseDTO'
import { InsuranceUpdateDTO } from 'plugin-insurances/shared/dto/InsuranceUpdateDTO'
import { MENU_AREA, MENU_PATHS, status } from 'plugin-insurances/utils/constants'
import { toOption } from 'plugin-insurances/utils/toOption'

import { EditorContainer, EditorFieldset } from './styled'

// eslint-disable-next-line prettier/prettier
type CommonOmit = 'id' | 'clientId' | 'companyId'| 'typeId'| 'active' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy' | 'client' | 'company' | 'type'
interface FormProps extends Omit<InsuranceResponseDTO, CommonOmit> {
  client: Option
  company: Option
  type: Option
  status: Option
}

const defaultValues: FormProps = {
  policyNumber: '',
  client: null,
  company: null,
  type: null,
  bonusClass: undefined,
  value: undefined,
  deductible: undefined,
  status: status[1],
  details: '',
  expiresAt: undefined
}

export function InsurancesEditorPage(): JSX.Element {
  const { id } = useParams()

  const { control, handleSubmit, reset, setValue } = useForm<FormProps>({ defaultValues, mode: 'all' })

  const [isLoading, setIsLoading] = React.useState(true)

  const navigate = useNavigate()

  async function handleCreateInsuranceCompany(name: string): Promise<void> {
    try {
      setIsLoading(true)
      const data = await createInsuranceCompany({ name })
      setValue('company', { value: data.id, label: data.name })
    } catch (error) {
      handleHTTPRequestError(error)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleCreateInsuranceType(name: string): Promise<void> {
    try {
      setIsLoading(true)
      const data = await createInsuranceType({ name })
      setValue('type', { value: data.id, label: data.name })
    } catch (error) {
      handleHTTPRequestError(error)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleGetInsuranceData(): Promise<void> {
    try {
      setIsLoading(true)

      if (id === 'new') {
        reset(defaultValues)
      } else {
        const response = await findInsurance(id)
        reset({
          policyNumber: response.policyNumber,
          client: { value: response.client.id, label: `${response.client.name} (${response.client.taxId})` },
          company: { value: response.company.id, label: response.company.name },
          type: { value: response.type.id, label: response.type.name },
          bonusClass: response.bonusClass,
          value: response.value,
          deductible: response.deductible,
          expiresAt: new Date(response.expiresAt),
          status: status.find((s) => s.value === response.active.toString()),
          details: response.details
        })
      }

      setIsLoading(false)
    } catch (e) {
      handleHTTPRequestError(e)
    }
  }

  async function handleSubmitForm(data: FormProps): Promise<void> {
    try {
      setIsLoading(true)

      const bodyData: InsuranceUpdateDTO = {
        policyNumber: data.policyNumber,
        clientId: data.client.value,
        companyId: data.company.value,
        typeId: data.type.value,
        bonusClass: data.bonusClass,
        value: Number(data.value),
        deductible: Number(data.deductible),
        expiresAt: data.expiresAt,
        active: data.status.value === 'true',
        details: data.details
      }

      if (id === 'new') {
        const createdUser = await createInsurance(bodyData)
        navigate(`/${MENU_AREA}/${MENU_PATHS.INSURANCES}/${createdUser.id}`)
      } else {
        await updateInsurance(id, bodyData)
        await handleGetInsuranceData()
      }
    } catch (e) {
      handleHTTPRequestError(e)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (id) {
      handleGetInsuranceData()
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FixedLoading position="absolute" enabled={isLoading} />
      <EditorContainer>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <EditorFieldset>
            <Card>
              <Card.Body>
                <div className="form-row">
                  <ClientSelectorHookForm
                    control={control}
                    name="client"
                    rules={{ required: true }}
                    label={i18n('Client')}
                  />
                </div>

                <div className="form-row">
                  <HookFormInput
                    control={control}
                    name="policyNumber"
                    rules={{ required: true }}
                    label={i18n('Policy number')}
                  />
                  <HookFormInput
                    control={control}
                    name="bonusClass"
                    rules={{ required: true }}
                    label={i18n('Bonus class')}
                    inputProps={{ type: 'number' }}
                  />
                  <AutoCompleteHookForm
                    control={control}
                    name="company"
                    rules={{ required: true }}
                    label={i18n('Insurance company')}
                    searchFunction={() => listInsuranceCompanies().then(toOption)}
                    selectProps={{
                      onCreateOption: handleCreateInsuranceCompany,
                      placeholder: '',
                      noOptionsMessage: () => i18n('No insurance companies found'),
                      formatCreateLabel: (v) => (
                        <>
                          {i18n('Create')} <strong>{v}</strong>
                        </>
                      )
                    }}
                  />
                  <AutoCompleteHookForm
                    control={control}
                    name="type"
                    rules={{ required: true }}
                    label={i18n('Insurance type')}
                    searchFunction={() => listInsuranceTypes().then(toOption)}
                    selectProps={{
                      onCreateOption: handleCreateInsuranceType,
                      placeholder: '',
                      noOptionsMessage: () => i18n('No insurance types found'),
                      formatCreateLabel: (v) => (
                        <>
                          {i18n('Create')} <strong>{v}</strong>
                        </>
                      )
                    }}
                  />
                </div>

                <div className="form-row">
                  <HookFormInput
                    control={control}
                    name="value"
                    rules={{ required: true }}
                    label={i18n('Premium')}
                    inputProps={{ type: 'number' }}
                  />
                  <HookFormInput
                    control={control}
                    name="deductible"
                    rules={{ required: true }}
                    label={i18n('Deductible')}
                    inputProps={{ type: 'number' }}
                  />
                  <HookFormDatePicker
                    control={control}
                    name="expiresAt"
                    rules={{ required: true }}
                    label={i18n('Expires at')}
                  />
                  <HookFormSelect
                    control={control}
                    name="status"
                    rules={{ required: true }}
                    label={i18n('Status')}
                    options={status}
                  />
                </div>

                <div className="form-row">
                  <HookFormTextarea control={control} name="details" label={i18n('Details')} />
                </div>
              </Card.Body>
            </Card>
          </EditorFieldset>
          <Card className="actions-footer">
            <Card.Body>
              <div className="actions-area">
                <Button type="submit" variant="primary">
                  {i18n(id === 'new' ? 'Create' : 'Save')}
                </Button>
                <span>{i18n('or')}</span>
                <Button type="button" variant="link" onClick={() => navigate(`/${MENU_AREA}/${MENU_PATHS.INSURANCES}`)}>
                  {i18n('cancel')}
                </Button>
              </div>

              <div className="required-warn">* {i18n('Required fields')}</div>
            </Card.Body>
          </Card>
        </form>
      </EditorContainer>
    </>
  )
}
