import { i18n } from 'methone/services/i18n'
import { menuService } from 'methone/services/menuService'
import { routerService } from 'methone/services/routerService'

import { InsurancePermissions } from 'plugin-insurances/shared/permissions'

import { enUS, ptBR } from './i18n'
import { InsuranceCompaniesEditorPage, InsuranceCompaniesPage } from './pages/InsuranceCompanies'
import { InsurancesEditorPage, InsurancesPage } from './pages/Insurances'
import { InsuranceTypesEditorPage, InsuranceTypesPage } from './pages/InsuranceTypes'
import { MENU_AREA, MENU_PATHS } from './utils/constants'

i18n.addItems('pt-BR', ptBR.values)
i18n.addItems('en-US', enUS.values)

const permissions = [InsurancePermissions.INSURANCES_MANAGER, InsurancePermissions.INSURANCES_ADMIN]

menuService.addItem({ basePath: `/${MENU_AREA}`, title: i18n('Insurance broker'), icon: 'fas fa-file-shield', permissions, order: 2 }) // eslint-disable-line prettier/prettier

menuService.addSubItem(`/${MENU_AREA}`, { path: `/${MENU_PATHS.INSURANCES}`, title: i18n('Insurances'), order: 1, element: InsurancesPage }) // eslint-disable-line prettier/prettier
routerService.addRoute({ path: `/${MENU_AREA}/${MENU_PATHS.INSURANCES}/:id`, title: i18n('Insurances'), element: InsurancesEditorPage, permissions }) // eslint-disable-line prettier/prettier

menuService.addSubItem(`/${MENU_AREA}`, { path: `/${MENU_PATHS.COMPANIES}`, title: i18n('Insurance companies'), order: 2, element: InsuranceCompaniesPage }) // eslint-disable-line prettier/prettier
routerService.addRoute({ path: `/${MENU_AREA}/${MENU_PATHS.COMPANIES}/:id`, title: i18n('Insurance companies'), element: InsuranceCompaniesEditorPage, permissions }) // eslint-disable-line prettier/prettier

menuService.addSubItem(`/${MENU_AREA}`, { path: `/${MENU_PATHS.TYPES}`, title: i18n('Insurance types'), order: 3, element: InsuranceTypesPage }) // eslint-disable-line prettier/prettier
routerService.addRoute({ path: `/${MENU_AREA}/${MENU_PATHS.TYPES}/:id`, title: i18n('Insurance types'), element: InsuranceTypesEditorPage, permissions }) // eslint-disable-line prettier/prettier
