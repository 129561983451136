import React from 'react'
import { Badge, Button, Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { ClientSelectorHookForm } from 'methone/components/ClientSelector'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormSelect, HookFormInput } from 'methone/components/ui/Forms'
import { Pagination } from 'methone/components/ui/Pagination'
import { SortedTable, SortedTableSort } from 'methone/components/ui/SortedTable'
import { useQueryParams } from 'methone/hooks'
import { dateService } from 'methone/services/dateService'
import { globalConfirmation } from 'methone/services/globalConfirmation'
import { i18n } from 'methone/services/i18n'
import { findClientResume, listClientGroups } from 'methone/services/rest/clients'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { listInsuranceCompanies } from 'plugin-insurances/services/insuranceCompanies'
import { listInsurances, deleteInsurance } from 'plugin-insurances/services/insurances'
import { listInsuranceTypes } from 'plugin-insurances/services/insuranceTypes'
import { InsuranceResponseDTO } from 'plugin-insurances/shared/dto/InsuranceResponseDTO'
import { expiresInNext, MENU_AREA, MENU_PATHS, status } from 'plugin-insurances/utils/constants'
import { toOption } from 'plugin-insurances/utils/toOption'

import { Form, InsurancesContainer, SolidDot } from './styled'

type Omits = 'clientId' | 'groups' | 'companyIds' | 'typeIds' | 'active' | 'expiresInNext'
interface FormData extends Omit<ListInsurancesFilters, Omits> {
  client: Option<string>
  groups: Options<string>
  companies: Options<string>
  types: Options<string>
  status: Option<string>
  expiresInNext: Option<string>
}

const defaultValues: FormData = {
  page: 1,
  perPage: 10,
  client: null,
  groups: [],
  companies: [],
  status: null,
  types: [],
  expiresInNext: null,
  policyNumber: ''
}

const bodyCellStyle = { justifyContent: 'center' }

export function InsurancesPage(): JSX.Element {
  const [params, setParams] = useQueryParams<ListInsurancesFilters>({ page: 1, perPage: 10 })
  const { control, handleSubmit, reset } = useForm<FormData>({ defaultValues })
  const [insuranceTypes, setInsuranceTypes] = React.useState<Options>([])
  const [insuranceCompanies, setInsuranceCompanies] = React.useState<Options>([])
  const [clientGroups, setClientGroups] = React.useState<Options>([])

  const { loading, mutate, run, data, error } = useRequest(listInsurances, { manual: true })
  const [isLoading, setIsLoading] = React.useState(false)
  const navigate = useNavigate()

  async function handleDelete({ id, policyNumber: key }: Partial<InsuranceResponseDTO>): Promise<void> {
    try {
      setIsLoading(true)
      const message = 'Are you sure you want to delete the insurance "{key}"?'
      if (await globalConfirmation.danger(i18n(message, { key }))) {
        await deleteInsurance(id)
        await mutate((data) => ({ ...data, entries: data.entries.filter((user) => user.id !== id) }))
      }
    } catch (err) {
      handleHTTPRequestError(err)
    } finally {
      setIsLoading(false)
    }
  }

  function onSubmit(fields: FormData, _e: React.BaseSyntheticEvent<object, any, any>): void {
    setParams({
      page: params.page,
      perPage: params.perPage,
      orderBy: params.orderBy,
      direction: params.direction,
      active: fields.status ? fields.status.value === 'true' : null,
      clientId: fields.client?.value,
      policyNumber: fields.policyNumber,
      typeIds: fields.types?.map((type) => type.value),
      companyIds: fields.companies?.map((type) => type.value),
      groups: fields.groups?.map((group) => group.value),
      expiresInNext: fields.expiresInNext ? fields.expiresInNext.value : null
    })
  }

  async function updateFormData(): Promise<void> {
    try {
      const client = params.clientId ? await findClientResume(params.clientId) : null

      const localInsuranceTypes = await listInsuranceTypes().then(toOption)
      setInsuranceTypes(localInsuranceTypes)

      const localInsuranceCompanies = await listInsuranceCompanies().then(toOption)
      setInsuranceCompanies(localInsuranceCompanies)

      const localClientGroups = await listClientGroups()
      setClientGroups(localClientGroups)

      reset({
        ...params,
        client: client ? { value: client.id, label: `${client.name} (${client.taxId})` } : null,
        groups: toOption(params.groups ?? []),
        companies: params.companyIds?.map((i) => localInsuranceTypes.find((t) => t.value === i)) ?? [],
        types: params.typeIds?.map((i) => localInsuranceTypes.find((t) => t.value === i)) ?? [],
        expiresInNext: expiresInNext.find((i) => i.value === params.expiresInNext),
        status: params.active != null ? status.find((i) => i.value === String(params.active)) : null
      })
    } catch (err) {
      handleHTTPRequestError(err)
    }
  }

  React.useEffect(() => {
    if (error) {
      handleHTTPRequestError(error)
    }
  }, [error])

  React.useEffect(() => {
    run(params)
    updateFormData()
  }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <InsurancesContainer>
      <Card style={{ marginBottom: 'var(--spacing-2)' }}>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ClientSelectorHookForm control={control} label={i18n('Client')} name="client" />
            <HookFormSelect control={control} label={i18n('Groups')} name="groups" isMulti options={clientGroups} />
            <HookFormInput control={control} label={i18n('Policy number')} name="policyNumber" />
            <HookFormSelect
              control={control}
              label={i18n('Expires in next')}
              name="expiresInNext"
              options={expiresInNext}
            />
            <HookFormSelect
              control={control}
              label={i18n('Insurance types')}
              name="types"
              isMulti
              options={insuranceTypes}
            />
            <HookFormSelect
              control={control}
              label={i18n('Insurance companies')}
              name="companies"
              isMulti
              options={insuranceCompanies}
            />
            <HookFormSelect control={control} label={i18n('Status')} name="status" options={status} />

            <div className="actions">
              <Button type="submit" variant="secondary">
                <i className="fas fa-filter" />
                {i18n('Apply filters')}
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={() => navigate(`/${MENU_AREA}/${MENU_PATHS.INSURANCES}/new`)}
              >
                <i className="fas fa-plus" />
                {i18n('New insurance')}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <SortedTable
        items={(data?.entries ?? [])?.map(({ client, ...insurance }) => ({
          id: insurance.id,
          active: (
            <SolidDot title={status.find((i) => i.value === String(params.active))?.label} status={insurance.active} />
          ),
          client: (
            <div>
              {client.group && (
                <Badge bg="outline-secondary" style={{ marginRight: 'var(--spacing-2)' }}>
                  {client.group}
                </Badge>
              )}
              <span>{client.name}</span>
            </div>
          ),
          policyNumber: insurance.policyNumber,
          company: insurance.company.name,
          type: insurance.type.name,
          expiresAt: dateService.formatDate(insurance.expiresAt)
        }))}
        columns={[
          { bodyCellStyle, title: '', columnRef: 'active', sortable: false, width: 45, showOn: 'desktop' },
          { title: i18n('Client'), columnRef: 'client', sortable: true },
          { title: i18n('Policy number'), columnRef: 'policyNumber', sortable: true },
          { title: i18n('Insurance company'), columnRef: 'company', sortable: true, width: 150, showOn: 'desktop' },
          { title: i18n('Insurance type'), columnRef: 'type', sortable: true, width: 180, showOn: 'desktop' },
          { title: i18n('Expires at'), columnRef: 'expiresAt', sortable: true, width: 100, showOn: 'desktop' }
        ]}
        sort={{
          column: params.orderBy,
          direction: params.direction as SortedTableSort,
          onSort: (orderBy, direction) => setParams({ ...params, orderBy, direction })
        }}
        actions={[
          {
            icon: 'far fa-edit',
            onClick: ({ id }) => navigate(`/${MENU_AREA}/${MENU_PATHS.INSURANCES}/${id}`)
          },
          {
            icon: 'far fa-trash-alt',
            onClick: ({ id, policyNumber }) => handleDelete({ id, policyNumber }),
            showOn: 'desktop'
          }
        ]}
      />
      <div style={{ paddingTop: 'var(--spacing-2)' }}>
        <Pagination
          currentPage={Number(params.page)}
          onChangePage={(page) => setParams({ ...params, page })}
          totalPages={Number(data?.totalPages ?? 1)}
        />
      </div>
      <FixedLoading enabled={loading || isLoading} />
    </InsurancesContainer>
  )
}
