import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'
import { api } from 'methone/utils/api'

import { PLUGIN_NAME, REST_ENDPOINTS } from 'plugin-insurances/shared/constants'
import { InsuranceTypeCreateDTO } from 'plugin-insurances/shared/dto/InsuranceTypeCreateDTO'
import { InsuranceTypeResponseDTO } from 'plugin-insurances/shared/dto/InsuranceTypeResponseDTO'
import { InsuranceTypeUpdateDTO } from 'plugin-insurances/shared/dto/InsuranceTypeUpdateDTO'

const ENDPOINT = `${PLUGIN_NAME}/${REST_ENDPOINTS.TYPES}`
// eslint-disable-next-line prettier/prettier
export async function listInsuranceTypes(params?: ListInsuranceTypesFilters): Promise<PaginationDTO<InsuranceTypeResponseDTO>> {
  const { data: response } = await api.get(`/${ENDPOINT}`, { params })

  return response
}

export async function findInsuranceType(id: string): Promise<InsuranceTypeResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}`)

  return response
}

export async function createInsuranceType(data: InsuranceTypeCreateDTO): Promise<InsuranceTypeResponseDTO> {
  const { data: response } = await api.post(`/${ENDPOINT}`, data)

  return response
}

export async function updateInsuranceType(id: string, data: InsuranceTypeUpdateDTO): Promise<InsuranceTypeResponseDTO> {
  const { data: response } = await api.put(`/${ENDPOINT}/${id}`, data)

  return response
}

export async function deleteInsuranceType(id: string): Promise<void> {
  await api.delete(`/${ENDPOINT}/${id}`)
}
