import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'
import { api } from 'methone/utils/api'

import { PLUGIN_NAME, REST_ENDPOINTS } from 'plugin-insurances/shared/constants'
import { InsuranceCompanyCreateDTO } from 'plugin-insurances/shared/dto/InsuranceCompanyCreateDTO'
import { InsuranceCompanyResponseDTO } from 'plugin-insurances/shared/dto/InsuranceCompanyResponseDTO'
import { InsuranceCompanyUpdateDTO } from 'plugin-insurances/shared/dto/InsuranceCompanyUpdateDTO'

const ENDPOINT = `${PLUGIN_NAME}/${REST_ENDPOINTS.COMPANIES}`

// eslint-disable-next-line prettier/prettier
export async function listInsuranceCompanies(params?: ListInsuranceCompaniesFilters): Promise<PaginationDTO<InsuranceCompanyResponseDTO>> {
  const { data: response } = await api.get(`/${ENDPOINT}`, { params })

  return response
}

export async function findInsuranceCompany(id: string): Promise<InsuranceCompanyResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}`)

  return response
}

export async function createInsuranceCompany(data: InsuranceCompanyCreateDTO): Promise<InsuranceCompanyResponseDTO> {
  const { data: response } = await api.post(`/${ENDPOINT}`, data)

  return response
}

// eslint-disable-next-line prettier/prettier
export async function updateInsuranceCompany(id: string, data: InsuranceCompanyUpdateDTO): Promise<InsuranceCompanyResponseDTO> {
  const { data: response } = await api.put(`/${ENDPOINT}/${id}`, data)

  return response
}

export async function deleteInsuranceCompany(id: string): Promise<void> {
  await api.delete(`/${ENDPOINT}/${id}`)
}
