import { Container } from 'react-bootstrap'

import styled from 'styled-components'

export const Form = styled.form`
  width: 100%;
  display: grid;
  grid-template-areas:
    'NAM'
    'ACT';
  grid-template-columns: 1fr;
  gap: var(--spacing-2);

  > .form-select-container--name {
    grid-area: NAM;
  }

  > .actions {
    grid-area: ACT;
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-2);

    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      gap: var(--spacing-2);
    }
  }
`

export const InsuranceTypesContainer = styled(Container)`
  padding: var(--spacing-4);

  .card {
    .card-body {
      display: flex;
      justify-content: space-between;
      gap: var(--spacing-2);

      > button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        gap: var(--spacing-2);

        > .badge {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
        }
      }
    }
  }

  > div {
    > table {
      .table-column-client {
        > div {
          display: flex;
          gap: var(--spacing-2);

          > span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;

            &.badge {
              height: 21px;
            }
          }
        }
      }
    }
  }
`
